import {
    TRYING_ADD_MEASUREMENTS,
    TRYING_ADD_MEASUREMENTS_SUCCESS,
    TRYING_ADD_MEASUREMENTS_ERROR,

    TRYING_CALCULATE_BODY_FAT,
    TRYING_CALCULATE_BODY_FAT_SUCCESS,
    TRYING_CALCULATE_BODY_FAT_ERROR,

    TRYING_DELETE_BODYGOAL_MEASUREMENT,
    TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS,
    TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR,

    TRYING_GET_ADD_MEASUREMENT_DETAILS,
    TRYING_GET_ADD_MEASUREMENT_DETAILS_SUCCESS,
    TRYING_GET_ADD_MEASUREMENT_DETAILS_ERROR,

    TRYING_GET_GARMIN_DATA,
    TRYING_GET_GARMIN_DATA_SUCCESS,
    TRYING_GET_GARMIN_DATA_ERROR,

    TRYING_INSERTEDIT_ENDURANCE_GOAL,
    TRYING_INSERTEDIT_ENDURANCE_GOAL_SUCCESS,
    TRYING_INSERTEDIT_ENDURANCE_GOAL_ERROR,

    TRYING_INSERTEDIT_EXERCISE_GOAL,
    TRYING_INSERTEDIT_EXERCISE_GOAL_SUCCESS,
    TRYING_INSERTEDIT_EXERCISE_GOAL_ERROR,

    TRYING_INSERTEDIT_FAVORITE_ENDURANCE,
    TRYING_INSERTEDIT_FAVORITE_ENDURANCE_SUCCESS,
    TRYING_INSERTEDIT_FAVORITE_ENDURANCE_ERROR,

    TRYING_INSERTEDIT_FAVORITE_EXERCISE,
    TRYING_INSERTEDIT_FAVORITE_EXERCISE_SUCCESS,
    TRYING_INSERTEDIT_FAVORITE_EXERCISE_ERROR,

    TRYING_GET_BODYGOAL_TRACKING_HISTORY,
    TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS,
    TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR,

    CLEAR_PROGRESS_DASHBOARD,
    TRYING_GET_PROGRESS_DASHBOARD,
    TRYING_GET_PROGRESS_DASHBOARD_SUCCESS,
    TRYING_GET_PROGRESS_DASHBOARD_ERROR,

    TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE,
    TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_SUCCESS,
    TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_ERROR,

    TRYING_GET_USER_WORKOUT_MAXES_STRENGTH,
    TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_SUCCESS,
    TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_ERROR,

    TRYING_REMOVE_FAVORITE_ENDURANCE,
    TRYING_REMOVE_FAVORITE_ENDURANCE_SUCCESS,
    TRYING_REMOVE_FAVORITE_ENDURANCE_ERROR,

    TRYING_REMOVE_FAVORITE_EXERCISE,
    TRYING_REMOVE_FAVORITE_EXERCISE_SUCCESS,
    TRYING_REMOVE_FAVORITE_EXERCISE_ERROR,

    CLEAR_VIEW_BODYGOAL_PROGRESS,
    TRYING_VIEW_BODYGOAL_PROGRESS,
    TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS,
    TRYING_VIEW_BODYGOAL_PROGRESS_ERROR,

    TRYING_VIEW_BODYGOAL_PROGRESS_CHART,
    TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS,
    TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR,

    CLEAR_VIEW_ENDURANCE_PROGRESS,
    TRYING_VIEW_ENDURANCE_PROGRESS,
    TRYING_VIEW_ENDURANCE_PROGRESS_SUCCESS,
    TRYING_VIEW_ENDURANCE_PROGRESS_ERROR,

    TRYING_VIEW_NUTRITION_PROGRESS,
    TRYING_VIEW_NUTRITION_PROGRESS_SUCCESS,
    TRYING_VIEW_NUTRITION_PROGRESS_ERROR,

    CLEAR_VIEW_STRENGTH_PROGRESS,
    TRYING_VIEW_STRENGTH_PROGRESS,
    TRYING_VIEW_STRENGTH_PROGRESS_SUCCESS,
    TRYING_VIEW_STRENGTH_PROGRESS_ERROR
} from '../Types';

const INITIAL_STATE = {
    BodyGoal: {},
    BodyGoals: [],
    Endurance: {},
    Endurances: { Endurances: [], OverallPercentage: 0 },
    Exercises: { Exercises: [], FilterSettings: {}, OverallPercentage: 0 },
    Strength: {},
    SurveyMeasurements: [],

    TryingAddMeasurements: false,
    TryingAddMeasurementsError: null,
    TryingCalculateBodyFat: false,
    TryingCalculateBodyFatError: null,
    TryingDeleteBodyGoalMeasurement: false,
    TryingDeleteBodyGoalMeasurementError: null,
    TryingGetAddMeasurementDetails: false,
    TryingGetAddMeasurementDetailsError: null,
    TryingGetBodyGoalTrackingHistory: false,
    TryingGetBodyGoalTrackingHistoryError: null,
    TryingGetGarminData: false,
    TryingGetGarminDataError: null,
    TryingGetProgressDashboard: false,
    TryingGetProgressDashboardError: null,
    TryingGetUserWorkoutMaxes_Endurance: false,
    TryingGetUserWorkoutMaxes_EnduranceError: null,
    TryingGetUserWorkoutMaxes_Strength: false,
    TryingGetUserWorkoutMaxes_StrengthError: null,
    TryingInsertEditEnduranceGoal: false,
    TryingInsertEditEnduranceGoalError: null,
    TryingInsertEditExerciseGoal: false,
    TryingInsertEditExerciseGoalError: null,
    TryingInsertEditFavoriteEndurance: false,
    TryingInsertEditFavoriteEnduranceError: null,
    TryingInsertEditFavoriteExercise: false,
    TryingInsertEditFavoriteExerciseError: null,
    TryingRemoveFavoriteEndurance: false,
    TryingRemoveFavoriteEnduranceError: null,
    TryingRemoveFavoriteExercise: false,
    TryingRemoveFavoriteExerciseError: null,
    TryingViewBodyGoalProgress: false,
    TryingViewBodyGoalProgressError: null,
    TryingViewBodyGoalProgressChart: false,
    TryingViewBodyGoalProgressChartError: null,
    TryingViewEnduranceProgress: false,
    TryingViewEnduranceProgressError: null,
    TryingViewNutritionProgress: false,
    TryingViewNutritionProgressError: null,
    TryingViewStrengthProgress: false,
    TryingViewStrengthProgressError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_ADD_MEASUREMENTS:
            return { ...state, TryingAddMeasurements: true, TryingAddMeasurementsError: null };
        case TRYING_ADD_MEASUREMENTS_ERROR:
            return { ...state, TryingAddMeasurements: false, TryingAddMeasurementsError: action.payload };
        case TRYING_ADD_MEASUREMENTS_SUCCESS:
            // eslint-disable-next-line
            var { AddMeasurementDetails, Chart, CurrentStatus, GetDashboardData, Measurement, TrackingHistory, TrackingHistoryIndex } = action.payload;

            // eslint-disable-next-line
            var NewBodyGoal = { ...state.BodyGoal };
            if (!GetDashboardData) {
                NewBodyGoal = { ...NewBodyGoal, AddMeasurementDetails, Chart, CurrentStatus };

                if (TrackingHistoryIndex !== null) NewBodyGoal.TrackingHistory.TrackingHistory[TrackingHistoryIndex].Measurement = Measurement;
                if (TrackingHistory) NewBodyGoal.TrackingHistory = TrackingHistory;   
            }

            return { ...state, BodyGoal: NewBodyGoal, TryingAddMeasurements: false, TryingAddMeasurementsError: null };

        case TRYING_CALCULATE_BODY_FAT:
            return { ...state, TryingCalculateBodyFat: true, TryingCalculateBodyFatError: null };
        case TRYING_CALCULATE_BODY_FAT_ERROR:
            return { ...state, TryingCalculateBodyFat: false, TryingCalculateBodyFatError: action.payload };
        case TRYING_CALCULATE_BODY_FAT_SUCCESS:
            return { ...state, TryingCalculateBodyFat: false, TryingCalculateBodyFatError: null };

        case TRYING_DELETE_BODYGOAL_MEASUREMENT:
            return { ...state, TryingDeleteBodyGoalMeasurement: true, TryingDeleteBodyGoalMeasurementError: null };
        case TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR:
            return { ...state, TryingDeleteBodyGoalMeasurement: false, TryingDeleteBodyGoalMeasurementError: action.payload };
        case TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS:
            // eslint-disable-next-line
            var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = action.payload;

            // eslint-disable-next-line
            var NewBodyGoal = { ...state.BodyGoal, AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory };

            return { ...state, BodyGoal: NewBodyGoal, TryingDeleteBodyGoalMeasurement: false, TryingDeleteBodyGoalMeasurementError: null };

        case TRYING_GET_ADD_MEASUREMENT_DETAILS:
            return { ...state, TryingGetAddMeasurementDetails: true, TryingGetAddMeasurementDetailsError: null };
        case TRYING_GET_ADD_MEASUREMENT_DETAILS_ERROR:
            return { ...state, TryingGetAddMeasurementDetails: false, TryingGetAddMeasurementDetailsError: action.payload };
        case TRYING_GET_ADD_MEASUREMENT_DETAILS_SUCCESS:
            return { ...state, TryingGetAddMeasurementDetails: false, TryingGetAddMeasurementDetailsError: null };

        case TRYING_GET_BODYGOAL_TRACKING_HISTORY:
            return { ...state, TryingGetBodyGoalTrackingHistory: true, TryingGetBodyGoalTrackingHistoryError: null };
        case TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR:
            return { ...state, TryingGetBodyGoalTrackingHistory: false, TryingGetBodyGoalTrackingHistoryError: action.payload };
        case TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS:
            // eslint-disable-next-line
            var { AddToExisting, TrackingHistory: { IsMoreResults, TotalRecords, TrackingHistory } } = action.payload;

            var NewTrackingHistory = { ...state.BodyGoal.TrackingHistory, IsMoreResults, TotalRecords };
            if (AddToExisting) NewTrackingHistory.TrackingHistory = [ ...NewTrackingHistory.TrackingHistory, ...TrackingHistory ];
            else NewTrackingHistory.TrackingHistory = [ ...TrackingHistory ];

            return { ...state, BodyGoal: { ...state.BodyGoal, TrackingHistory: NewTrackingHistory}, TryingGetBodyGoalTrackingHistory: false, TryingGetBodyGoalTrackingHistoryError: null };

        case TRYING_GET_GARMIN_DATA:
            return { ...state, TryingGetGarminData: true, TryingGetGarminDataError: null };
        case TRYING_GET_GARMIN_DATA_ERROR:
            return { ...state, TryingGetGarminData: false, TryingGetGarminDataError: action.payload };
        case TRYING_GET_GARMIN_DATA_SUCCESS:
            return { ...state, TryingGetGarminData: false, TryingGetGarminDataError: null };

        case CLEAR_PROGRESS_DASHBOARD:
            return { ...state, BodyGoals: [], Endurance: {}, Strength: {} };
        case TRYING_GET_PROGRESS_DASHBOARD:
            return { ...state, TryingGetProgressDashboard: true, TryingGetProgressDashboardError: null };
        case TRYING_GET_PROGRESS_DASHBOARD_ERROR:
            return { ...state, TryingGetProgressDashboard: false, TryingGetProgressDashboardError: action.payload };
        case TRYING_GET_PROGRESS_DASHBOARD_SUCCESS:
            // eslint-disable-next-line
            var { BodyGoals, Endurance, Strength, SurveyMeasurements } = action.payload;

            return { ...state, BodyGoals, Endurance, Strength, SurveyMeasurements, TryingGetProgressDashboard: false, TryingGetProgressDashboardError: null };

        case TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE:
            return { ...state, TryingGetUserWorkoutMaxes_Endurance: true, TryingGetUserWorkoutMaxes_EnduranceError: null };
        case TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_ERROR:
            return { ...state, TryingGetUserWorkoutMaxes_Endurance: false, TryingGetUserWorkoutMaxes_EnduranceError: action.payload };
        case TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_SUCCESS:
            // eslint-disable-next-line
            var { AddToExisting, Endurances: { Endurances, Endurances_IsMoreResults, TotalRecords } } = action.payload;

            var NewEndurances = { ...state.Endurances, Endurances_IsMoreResults, TotalRecords };
            if (AddToExisting) NewEndurances.Endurances = [ ...NewEndurances.Endurances, ...Endurances ];
            else NewEndurances.Endurances = [ ...Endurances ];

            return { ...state, Endurances: NewEndurances, TryingGetUserWorkoutMaxes_Endurance: false, TryingGetUserWorkoutMaxes_EnduranceError: null };
        
        case TRYING_GET_USER_WORKOUT_MAXES_STRENGTH:
            return { ...state, TryingGetUserWorkoutMaxes_Strength: true, TryingGetUserWorkoutMaxes_StrengthError: null };
        case TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_ERROR:
            return { ...state, TryingGetUserWorkoutMaxes_Strength: false, TryingGetUserWorkoutMaxes_StrengthError: action.payload };
        case TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_SUCCESS:
            // eslint-disable-next-line
            var { AddToExisting, Exercises: { Exercises, Exercises_IsMoreResults, TotalRecords } } = action.payload;

            var NewExercises = { ...state.Exercises, Exercises_IsMoreResults, TotalRecords };
            if (AddToExisting) NewExercises.Exercises = [ ...NewExercises.Exercises, ...Exercises ];
            else NewExercises.Exercises = [ ...Exercises ];

            return { ...state, Exercises: NewExercises, TryingGetUserWorkoutMaxes_Strength: false, TryingGetUserWorkoutMaxes_StrengthError: null };

        case TRYING_INSERTEDIT_ENDURANCE_GOAL:
            return { ...state, TryingInsertEditEnduranceGoal: true, TryingInsertEditEnduranceGoalError: null };
        case TRYING_INSERTEDIT_ENDURANCE_GOAL_ERROR:
            return { ...state, TryingInsertEditEnduranceGoal: false, TryingInsertEditEnduranceGoalError: action.payload };
        case TRYING_INSERTEDIT_ENDURANCE_GOAL_SUCCESS:
            return { ...state, TryingInsertEditEnduranceGoal: false, TryingInsertEditEnduranceGoalError: null };

        case TRYING_INSERTEDIT_EXERCISE_GOAL:
            return { ...state, TryingInsertEditExerciseGoal: true, TryingInsertEditExerciseGoalError: null };
        case TRYING_INSERTEDIT_EXERCISE_GOAL_ERROR:
            return { ...state, TryingInsertEditExerciseGoal: false, TryingInsertEditExerciseGoalError: action.payload };
        case TRYING_INSERTEDIT_EXERCISE_GOAL_SUCCESS:
            return { ...state, TryingInsertEditExerciseGoal: false, TryingInsertEditExerciseGoalError: null };

        case TRYING_INSERTEDIT_FAVORITE_ENDURANCE:
            return { ...state, TryingInsertEditFavoriteEndurance: true, TryingInsertEditFavoriteEnduranceError: null };
        case TRYING_INSERTEDIT_FAVORITE_ENDURANCE_ERROR:
            return { ...state, TryingInsertEditFavoriteEndurance: false, TryingInsertEditFavoriteEnduranceError: action.payload };
        case TRYING_INSERTEDIT_FAVORITE_ENDURANCE_SUCCESS:
            // eslint-disable-next-line
            var { Endurance } = action.payload;

            return { ...state, Endurance, TryingInsertEditFavoriteEndurance: false, TryingInsertEditFavoriteEnduranceError: null };

        case TRYING_INSERTEDIT_FAVORITE_EXERCISE:
            return { ...state, TryingInsertEditFavoriteExercise: true, TryingInsertEditFavoriteExerciseError: null };
        case TRYING_INSERTEDIT_FAVORITE_EXERCISE_ERROR:
            return { ...state, TryingInsertEditFavoriteExercise: false, TryingInsertEditFavoriteExerciseError: action.payload };
        case TRYING_INSERTEDIT_FAVORITE_EXERCISE_SUCCESS:
            // eslint-disable-next-line
            var { Strength } = action.payload;

            return { ...state, Strength, TryingInsertEditFavoriteExercise: false, TryingInsertEditFavoriteExerciseError: null };

        case TRYING_REMOVE_FAVORITE_ENDURANCE:
            return { ...state, TryingRemoveFavoriteEndurance: true, TryingRemoveFavoriteEnduranceError: null };
        case TRYING_REMOVE_FAVORITE_ENDURANCE_ERROR:
            return { ...state, TryingRemoveFavoriteEndurance: false, TryingRemoveFavoriteEnduranceError: action.payload };
        case TRYING_REMOVE_FAVORITE_ENDURANCE_SUCCESS:
            // eslint-disable-next-line
            var { Endurance } = action.payload;

            return { ...state, Endurance, TryingRemoveFavoriteEndurance: false, TryingRemoveFavoriteEnduranceError: null };

        case TRYING_REMOVE_FAVORITE_EXERCISE:
            return { ...state, TryingRemoveFavoriteExercise: true, TryingRemoveFavoriteExerciseError: null };
        case TRYING_REMOVE_FAVORITE_EXERCISE_ERROR:
            return { ...state, TryingRemoveFavoriteExercise: false, TryingRemoveFavoriteExerciseError: action.payload };
        case TRYING_REMOVE_FAVORITE_EXERCISE_SUCCESS:
            // eslint-disable-next-line
            var { Strength } = action.payload;

            return { ...state, Strength, TryingRemoveFavoriteExercise: false, TryingRemoveFavoriteExerciseError: null };

        case CLEAR_VIEW_BODYGOAL_PROGRESS:
            return { ...state, BodyGoal: {} };
        case TRYING_VIEW_BODYGOAL_PROGRESS:
            return { ...state, TryingViewBodyGoalProgress: true, TryingViewBodyGoalProgressError: null };
        case TRYING_VIEW_BODYGOAL_PROGRESS_ERROR:
            return { ...state, TryingViewBodyGoalProgress: false, TryingViewBodyGoalProgressError: action.payload };
        case TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS:
            // eslint-disable-next-line
            var BodyGoal = action.payload;

            return { ...state, BodyGoal, TryingViewBodyGoalProgress: false, TryingViewBodyGoalProgressError: null };


        case TRYING_VIEW_BODYGOAL_PROGRESS_CHART:
            return { ...state, TryingViewBodyGoalProgressChart: true, TryingViewBodyGoalProgressChartError: null };
        case TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR:
            return { ...state, TryingViewBodyGoalProgressChart: false, TryingViewBodyGoalProgressChartError: action.payload };
        case TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS:
            // eslint-disable-next-line
            var { Chart } = action.payload;

            return { ...state, BodyGoal: { ...state.BodyGoal, Chart }, TryingViewBodyGoalProgressChart: false, TryingViewBodyGoalProgressChartError: null };

        case CLEAR_VIEW_ENDURANCE_PROGRESS:
            return { ...state, Endurances: { Endurances: [], OverallPercentage: 0 } };
        case TRYING_VIEW_ENDURANCE_PROGRESS:
            return { ...state, TryingViewEnduranceProgress: true, TryingViewEnduranceProgressError: null };
        case TRYING_VIEW_ENDURANCE_PROGRESS_ERROR:
            return { ...state, TryingViewEnduranceProgress: false, TryingViewEnduranceProgressError: action.payload };
        case TRYING_VIEW_ENDURANCE_PROGRESS_SUCCESS:
            // eslint-disable-next-line
            var { Endurances, OverallPercentage } = action.payload;
            Endurances.OverallPercentage = OverallPercentage;

            return { ...state, Endurances, TryingViewEnduranceProgress: false, TryingViewEnduranceProgressError: null };

        case TRYING_VIEW_NUTRITION_PROGRESS:
            return { ...state, TryingViewNutritionProgress: true, TryingViewNutritionProgressError: null };
        case TRYING_VIEW_NUTRITION_PROGRESS_ERROR:
            return { ...state, TryingViewNutritionProgress: false, TryingViewNutritionProgressError: action.payload };
        case TRYING_VIEW_NUTRITION_PROGRESS_SUCCESS:
            return { ...state, TryingViewNutritionProgress: false, TryingViewNutritionProgressError: null };
        
        case CLEAR_VIEW_STRENGTH_PROGRESS:
            return { ...state, Exercises: { Exercises: [], FilterSettings: {}, OverallPercentage: 0 }};
        case TRYING_VIEW_STRENGTH_PROGRESS:
            return { ...state, TryingViewEnduranceProgress: true, TryingViewEnduranceProgressError: null };
        case TRYING_VIEW_STRENGTH_PROGRESS_ERROR:
            return { ...state, TryingViewEnduranceProgress: false, TryingViewEnduranceProgressError: action.payload };
        case TRYING_VIEW_STRENGTH_PROGRESS_SUCCESS:
            // eslint-disable-next-line
            var { Exercises, FilterSettings, OverallPercentage } = action.payload;
            Exercises = { ...Exercises, FilterSettings, OverallPercentage };

            return { ...state, Exercises, TryingViewEnduranceProgress: false, TryingViewEnduranceProgressError: null };

        default:
            return state;
    }
};
