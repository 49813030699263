import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GetClientProgress, InsertEditFavoriteEndurance, InsertEditFavoriteExercise, RemoveFavoriteEndurance, RemoveFavoriteExercise, ViewEnduranceTemplates } from '../../Services/Actions';
import history from '../../history';

import { AddEnduranceModalInfoContainer, BodyGoalChart, BodyGoalContainer, BodyGoalHeaderContainer, BodyGoalProgressContainer, BodyGoalsContainer, BodyGoalTitleContainer, CurrentStatusBox, CurrentStatusContainer, EnduranceTemplateItem, EnduranceTemplateList, FavoriteExerciseCompletionContainer, FavoriteExerciseContainer, ItemContainer, LinkContainer, ListContainer, ListItemsContainer, NESContainer2, NESInnerContainer, PlusIcon, PlusItemContainer, StyledDropdown, StyledHR, TotalContainer } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import CircleProgress from '../../Components/Charts/ProgressBars/Circle';
import GarminData from './GarminData';
import LineBar from '../../Components/Charts/ProgressBars/Line';
import LineGraph from '../../Components/Charts/LineGraph';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import AddExercise from '../../Modals/AddExercise';
import AddMeasurementsModal from '../../Modals/AddMeasurements';
import ExerciseDetail from '../../Modals/ExerciseDetail';
import Info from '../../Modals/Info';

import Plus from '../../Assets/Icons/Plus.png';

class ClientProgress extends React.PureComponent {
    _isMounted = false;

    state = {
        DisplayingFilter: null,
        DropdownSize: 0,
        EnduranceName: '',
        EnduranceTemplateId: null,
        EnduranceTemplates: [],
        EnduranceTemplates_Selected: [],
        FavoriteEndurance: {},
        FavoriteExercise: {},
        FavoriteOrder: null,
        Loading: true,
        ShowAddEnduranceModal: false,
        ShowAddExerciseModal: false,
        ShowAddMeasurementsModal: false,
        ShowExerciseDetailModal: false,
        ShowExerciseDetailPreview: {},
        ShowFavoriteEnduranceDetailModal: false,
        ShowFavoriteExerciseDetailModal: false
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId } = this.props;

        this.props.GetClientProgress({ ClientId }).then(() => {
            if (this._isMounted) {
                var EnduranceTemplates_Selected = this.props.Endurance.Endurances.Endurances.map(({ EnduranceTemplateId }) => +EnduranceTemplateId);

                this.setState({ EnduranceTemplates_Selected, Loading: false });
            }
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onAddEnduranceTemplate = () => {
        var { ClientId } = this.props;
        var { EnduranceTemplateId, FavoriteEndurance, FavoriteOrder } = this.state;

        if (+EnduranceTemplateId !== +FavoriteEndurance.EnduranceTemplateId) {
            this.setState({ EnduranceTemplates_Selected: [ ...this.state.EnduranceTemplates_Selected, +EnduranceTemplateId ] });
    
            this.props.InsertEditFavoriteEndurance({ ClientId, EnduranceTemplateId, FavoriteOrder });
        }
        
        this.onToggleShowAddEnduranceModal({ ShowAddEnduranceModal: false });
        this.onToggleShowFavoriteEnduranceDetailModal({ ShowFavoriteEnduranceDetailModal: false });
    }

    onAddExercises = SelectedExercises => {
        if (SelectedExercises.length) {
            var { ExerciseId } = SelectedExercises[0];
            var { ClientId } = this.props;
            var { FavoriteOrder } = this.state;

            this.props.InsertEditFavoriteExercise({ ClientId, ExerciseId, FavoriteOrder });
        }

        this.onToggleShowAddExerciseModal({ ShowAddExerciseModal: false });
        this.onToggleShowFavoriteExerciseDetailModal({ ShowFavoriteExerciseDetailModal: false });
    }

    onAddMeasurement = () => {
        var { ClientId } = this.props;

        this.onToggleShowAddMeasurementsModal(false);

        this.props.GetClientProgress({ ClientId });
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onGoToBodyGoal = ({ BodyGoalLink }) => {
        history.push(BodyGoalLink);
    }

    onLoadEnduranceTemplates = () => {
        var { ClientId } = this.props;

        this.props.ViewEnduranceTemplates({ ClientId }).then(({ Endurances }) => {
            this.setState({ EnduranceTemplates: Endurances });
        })
    }

    onRemoveFavoriteEndurance = EnduranceTemplateId => {
        var { ClientId } = this.props;

        var SelectedEnduranceTemplateIndex = this.state.EnduranceTemplates_Selected.indexOf(+EnduranceTemplateId);

        this.setState({ EnduranceTemplates_Selected: [ ...this.state.EnduranceTemplates_Selected.slice(0, SelectedEnduranceTemplateIndex), ...this.state.EnduranceTemplates_Selected.slice(SelectedEnduranceTemplateIndex + 1) ] });

        this.onToggleShowFavoriteEnduranceDetailModal({ ShowFavoriteEnduranceDetailModal: false });
        this.props.RemoveFavoriteEndurance({ ClientId, EnduranceTemplateId });
    }

    onRemoveFavoriteExercise = ExerciseId => {
        var { ClientId } = this.props;

        this.onToggleShowFavoriteExerciseDetailModal({ ShowFavoriteExerciseDetailModal: false });
        this.props.RemoveFavoriteExercise({ ClientId, ExerciseId });
    }

    onSelectEnduranceTemplate = ({ EnduranceName, EnduranceTemplateId }) => {
        this.setState({ EnduranceName, EnduranceTemplateId });
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleShowAddEnduranceModal = ({ FavoriteOrder, ShowAddEnduranceModal }) => {
        if (FavoriteOrder) this.setState({ FavoriteOrder, ShowAddEnduranceModal });
        else this.setState({ ShowAddEnduranceModal });
    }

    onToggleShowAddExerciseModal = ({ FavoriteOrder, ShowAddExerciseModal }) => {
        if (FavoriteOrder) this.setState({ FavoriteOrder, ShowAddExerciseModal });
        else this.setState({ ShowAddExerciseModal });
    }

    onToggleShowAddMeasurementsModal = ShowAddMeasurementsModal => {
        this.setState({ ShowAddMeasurementsModal });
    }

    onToggleShowExerciseDetailModal = (ShowExerciseDetailModal, Exercise) => {
        this.setState({ ShowExerciseDetailModal, ShowExerciseDetailPreview: Exercise || {} });
    }

    onToggleShowFavoriteEnduranceDetailModal = ({ EnduranceName = '', EnduranceTemplateId = null, FavoriteEndurance = {}, FavoriteOrder = null, ShowFavoriteEnduranceDetailModal }) => {
        this.setState({ EnduranceName, EnduranceTemplateId, FavoriteEndurance, FavoriteOrder, ShowFavoriteEnduranceDetailModal });
    }

    onToggleShowFavoriteExerciseDetailModal = ({ FavoriteExercise = {}, FavoriteOrder = null, ShowFavoriteExerciseDetailModal }) => {
        this.setState({ FavoriteExercise, FavoriteOrder, ShowFavoriteExerciseDetailModal });
    }

    onViewEnduranceDetail = EnduranceTemplateId => {
        var { ClientId } = this.props;

        history.push(`/client/${ClientId}/endurance/${EnduranceTemplateId}`);
    }

    onViewExerciseDetail = ExerciseId => {
        var { ClientId } = this.props;

        history.push(`/client/${ClientId}/exercise/${ExerciseId}`);
    }

    renderAddEnduranceModal = () => {
        var { ShowAddEnduranceModal } = this.state;

        if (ShowAddEnduranceModal) {
            return (
                <Info
                    InformationRenderer={this.renderAddEnduranceModalInfo}
                    OnHide={() => this.onToggleShowAddEnduranceModal({ ShowAddEnduranceModal: false })}
                    Show={ShowAddEnduranceModal}
                    Size="medium"
                    TextAlign="center"
                />
            );
        }
    }

    renderAddEnduranceModalInfo = () => {
        var { t } = this.props;
        var { Device, TryingViewEnduranceTemplates } = this.props;
        var { DisplayingFilter, DropdownSize, EnduranceName, EnduranceTemplateId, EnduranceTemplates } = this.state;

        var AddEnduranceButton = [{ BackgroundColor: 'abel-blue', BackgroundColorHover: 'blue-astronaut', Color: "white", ColorHover: "white", Disabled: !EnduranceTemplateId, FontFamily: 'semibold', OnClick: this.onAddEnduranceTemplate, Title: t('choose_interval') }];

        return (
            <AddEnduranceModalInfoContainer>
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('choose_interval')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <StyledDropdown
                    ContentRenderer={this.renderEnduranceTemplates}
                    Disabled={false}
                    ForceClose={DisplayingFilter !== 'EnduranceTemplates'}
                    GetSize={Height => this.onGetSize(Height)}
                    Height={DisplayingFilter === 'EnduranceTemplates' && DropdownSize}
                    IsList={false}
                    IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                    ItemId="EnduranceTemplateId"
                    Loading={TryingViewEnduranceTemplates}
                    NeedsToLoad={EnduranceTemplates.length === 0}
                    NeedsToLoadFunc={this.onLoadEnduranceTemplates}
                    OnToggleDropdown={ToggleStatus => this.onToggleDropdown('EnduranceTemplates', ToggleStatus)}
                    SelectedItemIds={[]}
                    Title={EnduranceName || t('choose_interval')}
                    TitleFontColor={EnduranceName ? 'blue-astronaut' : null}
                    TitleFontFamily="semibold"
                />

                <Spacer Size="medium" />

                <ButtonGroup Buttons={AddEnduranceButton} ButtonWidth="fit-content" />
            </AddEnduranceModalInfoContainer>
        );
    }

    renderAddExerciseModal = () => {
        var { ShowAddExerciseModal, ShowExerciseDetailModal } = this.state;

        if (ShowAddExerciseModal) {
            return (
                <AddExercise
                    DisplayNone={ShowExerciseDetailModal}
                    ExcludeExercises={this.props.Strength.Exercises.Exercises.map(({ ExerciseId }) => +ExerciseId)}
                    MaxExercises={1}
                    OnAddExercises={SelectedExercises => this.onAddExercises(SelectedExercises)}
                    OnHide={() => this.onToggleShowAddExerciseModal({ ShowAddExerciseModal: false })}
                    OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                    Show={ShowAddExerciseModal}
                />
            );
        }
    }

    renderAddMeasurementsModal = () => {
        var { ShowAddMeasurementsModal } = this.state;
        var { ClientId } = this.props;

        if (ShowAddMeasurementsModal) {
            return (
                <AddMeasurementsModal
                    ClientId={ClientId}
                    OnAddMeasurement={this.onAddMeasurement}
                    OnHide={() => this.onToggleShowAddMeasurementsModal(false)}
                    Show={ShowAddMeasurementsModal}
                />
            );
        }
    }

    renderBodyGoalCurrentStatus = ({ CurrentValue, StartValue }) => {
        var { t } = this.props;

        return (
            <CurrentStatusContainer>
                <CurrentStatusBox>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('StartValueText')} TextAlign="center" WordBreak="keep-all" />

                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${StartValue}`} TextAlign="center" WordBreak="keep-all" />
                </CurrentStatusBox>
                <CurrentStatusBox>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('EndValueText')} TextAlign="center" WordBreak="keep-all" />

                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${CurrentValue}`} TextAlign="center" WordBreak="keep-all" />
                </CurrentStatusBox>
            </CurrentStatusContainer>
        );
    }

    renderBodyGoals = () => {
        var { t } = this.props;
        var { BodyGoals } = this.props;

        return (
            <>
                {
                    BodyGoals.map(BodyGoal => {
                        var { ActiveGoal, BodyGoalAreaId, BodyGoalType, Chart, CompletionStatus, CurrentValue, GoalMax, GoalMin, ProgressLine, StartValue, SummaryValue, SubTitle, Title, Units } = BodyGoal;

                        var BodyGoalLink = `/client/${this.props.ClientId}/${BodyGoalType}`;
                        if (BodyGoalType === 'circumference') BodyGoalLink = `${BodyGoalLink}/${BodyGoalAreaId}`;

                        return (
                            <BodyGoalContainer key={`${BodyGoalType}-${BodyGoalAreaId}`}>
                                <BodyGoalHeaderContainer>
                                    <BodyGoalTitleContainer>
                                    <LinkContainer to={BodyGoalLink}>
                                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={Title} TextAlign="center" />
                                    </LinkContainer>

                                        <Spacer Size="extra-extra-small" />

                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${SubTitle} (${Units})`} TextAlign="center" />

                                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${SummaryValue} ${Units} ${t(`${CurrentValue >= StartValue ? 'up' : 'down'}`).toLowerCase()}`} TextAlign="center" />
                                    </BodyGoalTitleContainer>
                                    <BodyGoalProgressContainer>
                                        {
                                            !!+ActiveGoal ?
                                            <LineBar
                                                CompletionStatus={CompletionStatus}
                                                CurrentValue={CurrentValue}
                                                GoalMax={GoalMax}
                                                GoalMin={GoalMin}
                                                ProgressLine={ProgressLine}
                                                Units={Units}
                                            />
                                        :
                                            this.renderBodyGoalCurrentStatus({ CurrentValue, StartValue })
                                        }
                                    </BodyGoalProgressContainer>
                                </BodyGoalHeaderContainer>

                                <StyledHR />

                                <BodyGoalChart>
                                    <LineGraph
                                        Goals={Chart.Goals}
                                        Increment={Chart.Increment}
                                        Maximum={Chart.Maximum}
                                        Minimum={Chart.Minimum}
                                        Measurements={Chart.Measurements}
                                        TimePeriod="1M"
                                        Units={Units}
                                    />
                                </BodyGoalChart>

                                <Spacer Size="small" />

                                <ButtonGroup Buttons={[{ FontFamily: 'semibold', OnClick: () => this.onGoToBodyGoal({ BodyGoalLink }), Title: t('track_progress') }]} />
                            </BodyGoalContainer>
                        );
                    })
                }
            </>
        );
    }

    renderEndurance = ({ Endurances, OverallPercentage }) => {
        var { t } = this.props;

        var EnduranceList = [ ...Endurances.Endurances ];
        var EnduranceLength = EnduranceList.length;

        // Add Extra Plus Sign Endurance If Required
            if (EnduranceLength < 5) {
                for (var i = EnduranceLength; i < 5; i++) {
                    EnduranceList.push({ PlusItem: 1 });
                }
            }

        return (
            <NESContainer2>
                <LinkContainer to={`/client/${this.props.ClientId}/endurance`}>
                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Endurance')} TextAlign="center" />
                </LinkContainer>

                <StyledHR />

                <NESInnerContainer>
                    <TotalContainer>
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('custom')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <CircleProgress Percentage={OverallPercentage} />
                    </TotalContainer>

                    <ListContainer>
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('favorite_endurances')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <ListItemsContainer>
                            {this.renderEndurances({ Endurances: EnduranceList })}
                        </ListItemsContainer>
                    </ListContainer>
                </NESInnerContainer>
            </NESContainer2>
        );
    }

    renderEndurances = ({ Endurances }) => {
        return (
            <>
                {
                    Endurances.map((Endurance, EnduranceIndex) => {
                        var { CompletionPercentage, EnduranceName, EnduranceShortName, EnduranceTemplateId, PlusItem } = Endurance;

                        // eslint-disable-next-line
                        var PlusItem = PlusItem || 0;
                        var Key = PlusItem ? `Index_${EnduranceIndex}` : EnduranceTemplateId;

                        if (PlusItem) {
                            return (
                                <ItemContainer key={Key} onClick={() => this.onToggleShowAddEnduranceModal({ FavoriteOrder: EnduranceIndex + 1, ShowAddEnduranceModal: true })}>
                                    <PlusItemContainer>
                                        <PlusIcon alt="Add Favorite Endurance" src={Plus} />
                                    </PlusItemContainer>
                                </ItemContainer>
                            );
                        }

                        return (
                            <ItemContainer key={Key} onClick={() => this.onToggleShowFavoriteEnduranceDetailModal({ EnduranceName, EnduranceTemplateId, FavoriteEndurance: Endurance, FavoriteOrder: EnduranceIndex + 1, ShowFavoriteEnduranceDetailModal: true })}>
                                <CircleProgress InnerText={EnduranceShortName} Percentage={CompletionPercentage} />
                            </ItemContainer>
                        );
                    })
                }
            </>
        );
    }

    renderEnduranceTemplates = () => {
        var { EnduranceTemplates, EnduranceTemplates_Selected, FavoriteEndurance } = this.state;

        return (
            <EnduranceTemplateList>
                {
                    EnduranceTemplates.map(({ EnduranceName, EnduranceTemplateId }) => {
                        var Selected = EnduranceTemplateId === this.state.EnduranceTemplateId;
                        var Disabled = EnduranceTemplates_Selected.indexOf(EnduranceTemplateId) !== -1 && !Selected && EnduranceTemplateId !== FavoriteEndurance.EnduranceTemplateId;

                        return (
                            <EnduranceTemplateItem key={EnduranceTemplateId} Disabled={Disabled} onClick={Disabled ? () => null : () => this.onSelectEnduranceTemplate({ EnduranceName, EnduranceTemplateId })} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={EnduranceName} TextAlign="left" />
                            </EnduranceTemplateItem>
                        );
                    })
                }
            </EnduranceTemplateList>
        );
    }

    renderExerciseDetailModal = () => {
        var { ShowExerciseDetailModal, ShowExerciseDetailPreview } = this.state;

        if (ShowExerciseDetailModal) {
            return (
                <ExerciseDetail
                    ExerciseDetail_Preview={ShowExerciseDetailPreview}
                    OnHide={() => this.onToggleShowExerciseDetailModal(false)}
                    Show={ShowExerciseDetailModal}
                />
            );
        }
    }

    renderExercises = ({ Exercises }) => {
        return (
            <>
                {
                    Exercises.map((Exercise, ExerciseIndex) => {
                        var { CompletionPercentage, ExerciseId, ExerciseImage, PlusItem } = Exercise;

                        // eslint-disable-next-line
                        var PlusItem = PlusItem || 0;
                        var Key = PlusItem ? `Index_${ExerciseIndex}` : ExerciseId;

                        if (PlusItem) {
                            return (
                                <ItemContainer key={Key} onClick={() => this.onToggleShowAddExerciseModal({ FavoriteOrder: ExerciseIndex + 1, ShowAddExerciseModal: true })}>
                                    <PlusItemContainer>
                                        <PlusIcon alt="Add Favorite Exercise" src={Plus} />
                                    </PlusItemContainer>
                                </ItemContainer>
                            );
                        }

                        return (
                            <ItemContainer key={Key} onClick={() => this.onToggleShowFavoriteExerciseDetailModal({ FavoriteExercise: Exercise, FavoriteOrder: ExerciseIndex + 1, ShowFavoriteExerciseDetailModal: true })}>
                                <CircleProgress Percentage={CompletionPercentage} Picture={ExerciseImage} />
                            </ItemContainer>
                        );
                    })
                }
            </>
        );
    }

    renderFavoriteEndurancesDetailInfo = () => {
        var { t } = this.props;
        var { FavoriteEndurance: { CompletionPercentage, EnduranceName, EnduranceShortName, EnduranceTemplateId } } = this.state;

        var FavoriteEnduranceButtons = [
            { FontFamily: 'semibold', OnClick: () => this.onToggleShowAddEnduranceModal({ ShowAddEnduranceModal: true }), Title: t('change_with_first_char_uppercase') },
            { FontFamily: 'semibold', OnClick: () => this.onRemoveFavoriteEndurance(EnduranceTemplateId), Title: t('remove') },
            { FontFamily: 'semibold', OnClick: () => this.onViewEnduranceDetail(EnduranceTemplateId), Title: t('track_progress') }
        ];

        return (
            <FavoriteExerciseContainer>
                <FavoriteExerciseCompletionContainer>
                    <CircleProgress InnerText={EnduranceShortName} Percentage={CompletionPercentage} />
                </FavoriteExerciseCompletionContainer>

                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={EnduranceName} TextAlign="center" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${CompletionPercentage}%`} TextAlign="center" />

                <Spacer Size="medium" />

                <ButtonGroup Buttons={FavoriteEnduranceButtons} />
            </FavoriteExerciseContainer>
        );
    }

    renderFavoriteExercisesDetailInfo = () => {
        var { t } = this.props;
        var { FavoriteExercise: { CompletionPercentage, ExerciseId, ExerciseImage, ExerciseName } } = this.state;

        var FavoriteExerciseButtons = [
            { FontFamily: 'semibold', OnClick: () => this.onToggleShowAddExerciseModal({ ShowAddExerciseModal: true }), Title: t('change_with_first_char_uppercase') },
            { FontFamily: 'semibold', OnClick: () => this.onRemoveFavoriteExercise(ExerciseId), Title: t('remove') },
            { FontFamily: 'semibold', OnClick: () => this.onViewExerciseDetail(ExerciseId), Title: t('track_progress') }
        ];

        return (
            <FavoriteExerciseContainer>
                <FavoriteExerciseCompletionContainer>
                    <CircleProgress Percentage={CompletionPercentage} Picture={ExerciseImage} />
                </FavoriteExerciseCompletionContainer>

                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={ExerciseName} TextAlign="center" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${CompletionPercentage}%`} TextAlign="center" />

                <Spacer Size="medium" />

                <ButtonGroup Buttons={FavoriteExerciseButtons} />
            </FavoriteExerciseContainer>
        );
    }

    renderGarminData = () => {
        return <GarminData UserId={this.props.ClientId} />
    }

    renderShowFavoriteEnduranceDetailModal = () => {
        var { ShowAddEnduranceModal, ShowFavoriteEnduranceDetailModal } = this.state;

        if (ShowFavoriteEnduranceDetailModal) {
            return (
                <Info
                    DisplayNone={ShowAddEnduranceModal}
                    InformationRenderer={this.renderFavoriteEndurancesDetailInfo}
                    OnHide={() => this.onToggleShowFavoriteEnduranceDetailModal(false)}
                    Show={ShowFavoriteEnduranceDetailModal}
                    Size="medium"
                    TextAlign="center"
                />
            );
        }
    }

    renderShowFavoriteExerciseDetailModal = () => {
        var { ShowAddExerciseModal, ShowExerciseDetailModal, ShowFavoriteExerciseDetailModal } = this.state;

        if (ShowFavoriteExerciseDetailModal) {
            return (
                <Info
                    DisplayNone={ShowAddExerciseModal || ShowExerciseDetailModal}
                    InformationRenderer={this.renderFavoriteExercisesDetailInfo}
                    OnHide={() => this.onToggleShowFavoriteExerciseDetailModal(false)}
                    Show={ShowFavoriteExerciseDetailModal}
                    Size="medium"
                    TextAlign="center"
                />
            );
        }
    }

    renderStrength = ({ Exercises, OverallPercentage }) => {
        var { t } = this.props;

        var ExerciseList = [ ...Exercises.Exercises ];
        var ExerciseLength = ExerciseList.length;

        // Add Extra Plus Sign Exercises If Required
            if (ExerciseLength < 5) {
                for (var i = ExerciseLength; i < 5; i++) {
                    ExerciseList.push({ PlusItem: 1 });
                }
            }

        return (
            <NESContainer2>
                <LinkContainer to={`/client/${this.props.ClientId}/strength`}>
                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Strength')} TextAlign="center" />
                </LinkContainer>

                <StyledHR />

                <NESInnerContainer>
                    <TotalContainer>
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('custom')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <CircleProgress Percentage={OverallPercentage} />
                    </TotalContainer>

                    <ListContainer>
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('favorite_exercises')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <ListItemsContainer>
                            {this.renderExercises({ Exercises: ExerciseList })}
                        </ListItemsContainer>
                    </ListContainer>
                </NESInnerContainer>
            </NESContainer2>
        );
    }

    renderSurveyData = () => {
        var { t } = this.props;
        var { SurveyMeasurements } = this.props;

        return (
            <>
                {
                    SurveyMeasurements.map(BodyGoal => {
                        var { ActiveGoal, BodyGoalAreaId, BodyGoalType, Chart, CompletionStatus, CurrentValue, GoalMax, GoalMin, ProgressLine, StartValue, SummaryValue, SubTitle, Title } = BodyGoal;

                        var BodyGoalLink = `/client/${this.props.ClientId}/surveyprogress/${BodyGoalType}`;

                        return (
                            <BodyGoalContainer key={`${BodyGoalType}-${BodyGoalAreaId}`}>
                                <BodyGoalHeaderContainer>
                                    <BodyGoalTitleContainer>
                                    <LinkContainer to={BodyGoalLink}>
                                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={Title} TextAlign="center" />
                                    </LinkContainer>

                                        <Spacer Size="extra-extra-small" />

                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${SubTitle}`} TextAlign="center" />

                                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${SummaryValue} ${t(`${CurrentValue >= StartValue ? 'up' : 'down'}`).toLowerCase()}`} TextAlign="center" />
                                    </BodyGoalTitleContainer>
                                    <BodyGoalProgressContainer>
                                        {
                                            !!+ActiveGoal ?
                                            <LineBar
                                                CompletionStatus={CompletionStatus}
                                                CurrentValue={CurrentValue}
                                                GoalMax={GoalMax}
                                                GoalMin={GoalMin}
                                                ProgressLine={ProgressLine}
                                                Units=""
                                            />
                                        :
                                            this.renderBodyGoalCurrentStatus({ CurrentValue, StartValue })
                                        }
                                    </BodyGoalProgressContainer>
                                </BodyGoalHeaderContainer>

                                <StyledHR />

                                <BodyGoalChart>
                                    <LineGraph
                                        Goals={Chart.Goals}
                                        Increment={Chart.Increment}
                                        Maximum={Chart.Maximum}
                                        Minimum={Chart.Minimum}
                                        Measurements={Chart.Measurements}
                                        TimePeriod="1M"
                                        Units=""
                                    />
                                </BodyGoalChart>

                                <Spacer Size="small" />

                                <ButtonGroup Buttons={[{ FontFamily: 'semibold', OnClick: () => this.onGoToBodyGoal({ BodyGoalLink }), Title: t('track_progress') }]} />
                            </BodyGoalContainer>
                        );
                    })
                }
            </>
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { Endurance, Strength, TryingAddMeasurements, TryingGetProgressDashboard, TryingInsertEditFavoriteEndurance, TryingInsertEditFavoriteExercise, TryingRemoveFavoriteEndurance, TryingRemoveFavoriteExercise } = this.props;

        var { t } = this.props;
        var AddMeasurementButton = [{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowAddMeasurementsModal(true), Title: t('measurement_add') }];

        return (
            <>
                {(TryingAddMeasurements || TryingGetProgressDashboard || TryingInsertEditFavoriteEndurance || TryingInsertEditFavoriteExercise || TryingRemoveFavoriteEndurance || TryingRemoveFavoriteExercise) && <Loading />}
                <div>
                    {Strength && this.renderStrength(Strength)}

                    <Spacer Size="medium" />

                    {Endurance && this.renderEndurance(Endurance)}

                    <Spacer Size="medium" />

                    <BodyGoalsContainer>
                        {this.renderBodyGoals()}

                        {this.renderSurveyData()}
                        {this.renderGarminData()}
                    </BodyGoalsContainer>

                    <Spacer Size="small" />

                    <ButtonGroup Buttons={AddMeasurementButton} />
                </div>

                {this.renderAddEnduranceModal()}
                {this.renderAddExerciseModal()}
                {this.renderAddMeasurementsModal()}
                {this.renderExerciseDetailModal()}
                {this.renderShowFavoriteEnduranceDetailModal()}
                {this.renderShowFavoriteExerciseDetailModal()}
            </>
        );
    }
}

ClientProgress.propTypes = {
    ClientId: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.Client.ClientDetails,

        BodyGoals: state.Progress.BodyGoals,
        Endurance: state.Progress.Endurance,
        Strength: state.Progress.Strength,
        SurveyMeasurements: state.Progress.SurveyMeasurements,

        TryingViewEnduranceTemplates: state.Endurances.TryingViewEnduranceTemplates,
        TryingViewEnduranceTemplatesError: state.Endurances.TryingViewEnduranceTemplatesError,
        
        TryingAddMeasurements: state.Progress.TryingAddMeasurements,
        TryingAddMeasurementsError: state.Progress.TryingAddMeasurementsError,
        TryingGetProgressDashboard: state.Progress.TryingGetProgressDashboard,
        TryingGetProgressDashboardError: state.Progress.TryingGetProgressDashboardError,
        TryingInsertEditFavoriteEndurance: state.Progress.TryingInsertEditFavoriteEndurance,
        TryingInsertEditFavoriteEnduranceError: state.Progress.TryingInsertEditFavoriteEnduranceError,
        TryingInsertEditFavoriteExercise: state.Progress.TryingInsertEditFavoriteExercise,
        TryingInsertEditFavoriteExerciseError: state.Progress.TryingInsertEditFavoriteExerciseError,
        TryingRemoveFavoriteEndurance: state.Progress.TryingRemoveFavoriteEndurance,
        TryingRemoveFavoriteEnduranceError: state.Progress.TryingRemoveFavoriteEnduranceError,
        TryingRemoveFavoriteExercise: state.Progress.TryingRemoveFavoriteExercise,
        TryingRemoveFavoriteExerciseError: state.Progress.TryingRemoveFavoriteExerciseError
    };
};

export default withTranslation()(connect(mapStateToProps, { GetClientProgress, InsertEditFavoriteEndurance, InsertEditFavoriteExercise, RemoveFavoriteEndurance, RemoveFavoriteExercise, ViewEnduranceTemplates } )(ClientProgress));